<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="row">
				<div class="col-md-12 text-right">
					<a-button class="btn btn-info" icon="plus" @click="onAddRecord">Nueva Solicitud</a-button>
					<a-button class="btn btn-success ml5" icon="reload" @click="initModule" />
				</div>
			</div>
			<div class="row pt10">
				<div class="col-md-12">
					<a-table :columns="columns" :dataSource="developmentRequestsList" rowKey="key" :pagination="false">
						<div slot="titleColumn" slot-scope="record">
							{{ record.title }}
							<br />
							<a-tag color="green">Completado</a-tag>
						</div>
						<div slot="actions" slot-scope="record">
							<a-button type="info" size="small" icon="edit" @click="onEditRecord(record.id)" />
						</div>
					</a-table>
				</div>
			</div>
		</a-spin>
		<a-modal :visible="modalVisible" :footer="null" title="Solicitud de Desarrollo" width="80%">
			<developmentRequestsDetail ref="developmentRequestsDetail" @close="onCloseModal" />
		</a-modal>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import utilities from '@/services/utilities'
import developmentRequestsDetail from '@/components/developmentRequests/detail'

export default {
	name: 'developmentRequestsView',
	components: {
		developmentRequestsDetail,
	},
	computed: {
		...mapGetters('developmentRequests', ['spinnerLoaderLabel', 'spinnerLoader', 'developmentRequestsList', 'actualRecord']),
	},
	data() {
		return {
			modalVisible: false,
			columns: [
				{
					title: 'ID',
					dataIndex: 'id',
					width: '10%',
					align: 'center',
				},
				{
					title: 'Solicitante',
					dataIndex: 'fullName',
					width: '20%',
					align: 'center',
				},
				{
					title: 'Título',
					scopedSlots: { customRender: 'titleColumn' },
					width: '50%',
					align: 'center',
				},
				{
					title: 'Solicitud',
					dataIndex: 'created_at',
					width: '10%',
					align: 'center',
				},
				{
					title: 'Acciones',
					scopedSlots: { customRender: 'actions' },
					width: '10%',
					align: 'right',
				},
			],
		}
	},
	mounted() {
		this.initModule()
	},
	destroyed() {
		this.$store.commit('developmentRequests/SET_STATE', {
			developmentRequestsList: [],
		})
	},
	methods: {
		initModule() {
			this.$store.dispatch('developmentRequests/GET')
		},
		onAddRecord() {
			this.$store.commit('developmentRequests/SET_STATE', {
				actualRecord: {},
			})
			this.modalVisible = true
		},
		onEditRecord(id) {
			this.$store.dispatch('developmentRequests/GET_ONE', id)
		},
		onCloseModal() {
			this.modalVisible = false
			setTimeout(() => {
				this.$refs['developmentRequestsDetail'].resetField()
			}, 500)
		},
	},
	watch: {
		actualRecord: {
			deep: true,
			handler(newData) {
				if (utilities.objectValidate(newData, 'id', false)) {
					this.modalVisible = true
				}
			},
		},
	},
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>