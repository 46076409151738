<template>
	<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
		<a-tabs>
			<a-tab-pane tab="General" key="general" force-render>
				<a-form :form="formDetail" @submit="handleSubmitDetails">
					<div class="row">
						<div class="col-md-12">
							<a-form-item label="Título">
								<a-input
									autocomplete="off"
									v-decorator="[
										'title',
										{
											rules: [
												{
													required: true,
													message: 'Ingresa tu número de ticket',
												},
											],
										},
									]"
								/>
							</a-form-item>
						</div>
						<div class="col-md-12">
							<a-form-item label="Descripción">
								<a-textarea
									v-decorator="[
										'description',
										{
											rules: [
												{
													required: true,
													message: 'Ingresa tu número de ticket',
												},
											],
										},
									]"
								/>
							</a-form-item>
						</div>
					</div>
					<div class="row pt20">
						<div class="col-md-12 text-right">
							<a-button key="back" @click="onCloseModal"> Cancelar </a-button>
							<a-button key="submit" class="btn btn-success ml7" htmlType="submit"> Guardar </a-button>
						</div>
					</div>
				</a-form>
			</a-tab-pane>
			<a-tab-pane tab="Comentarios" key="comments" force-render v-if="!isNewRecord">
				<a-form :form="formComments" @submit="handleSubmitComments">
					<div class="row">
						<div class="col-md-12">
							<a-form-item label="Comentario">
								<a-textarea
									v-decorator="[
										'content',
										{
											rules: [
												{
													required: true,
													message: 'Ingresa tu número de ticket',
												},
											],
										},
									]"
								/>
							</a-form-item>
						</div>
					</div>
					<div class="row pt20">
						<div class="col-md-12 text-right">
							<a-button key="back" @click="onCloseModal"> Cancelar </a-button>
							<a-button key="submit" class="btn btn-success ml7" htmlType="submit"> Guardar </a-button>
						</div>
					</div>
				</a-form>
				<hr class="hrDotted" />
				<div v-for="(comment, index) in actualRecord.comments" :key="index">
					<div>
						<b>{{ comment.fullName }}</b> | <small>{{ comment.created_at }}</small>
					</div>
					<div>
						{{ comment.content }}
					</div>
					<hr />
				</div>
			</a-tab-pane>
		</a-tabs>
	</a-spin>
</template>

<script>
import { mapGetters } from 'vuex'
import utilities from '@/services/utilities'

export default {
	name: 'developmentRequestsDetail',
	computed: {
		...mapGetters('developmentRequests', ['spinnerLoaderLabel', 'spinnerLoader', 'actualRecord']),
		isNewRecord() {
			return !utilities.objectValidate(this.actualRecord, 'id', false)
		},
	},
	data: function () {
		return {
			formDetail: this.$form.createForm(this),
			formComments: this.$form.createForm(this),
		}
	},
	methods: {
		resetField() {
			this.formDetail.resetFields()
			this.formComments.resetFields()
		},
		handleSubmitDetails(e) {
			e.preventDefault()
			this.formDetail.validateFields((err, values) => {
				if (!err) {
					let payload = {
						...values,
					}
					if (this.isNewRecord) {
						this.$store.dispatch('developmentRequests/CREATE', payload)
					} else {
						payload.id = this.actualRecord.id
						this.$store.dispatch('developmentRequests/UPDATE', payload)
					}
				}
			})
		},
		handleSubmitComments(e) {
			e.preventDefault()
			this.formComments.validateFields((err, values) => {
				if (!err) {
					let payload = {
						...values,
						id: this.actualRecord.id,
					}
					this.$store.dispatch('developmentRequests/ADD_COMMENT', payload)
				}
			})
		},
		onCloseModal() {
			this.$emit('close')
		},
	},
	watch: {
		actualRecord: {
			deep: true,
			handler(newData) {
				if (utilities.objectValidate(newData, 'id', false)) {
					this.formDetail.setFieldsValue({
						title: newData.title,
						description: newData.description,
					})
				}
			},
		},
	},
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>