var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-spin',{attrs:{"tip":_vm.spinnerLoaderLabel,"size":"large","spinning":_vm.spinnerLoader}},[_c('a-tabs',[_c('a-tab-pane',{key:"general",attrs:{"tab":"General","force-render":""}},[_c('a-form',{attrs:{"form":_vm.formDetail},on:{"submit":_vm.handleSubmitDetails}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('a-form-item',{attrs:{"label":"Título"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
									'title',
									{
										rules: [
											{
												required: true,
												message: 'Ingresa tu número de ticket',
											},
										],
									},
								]),expression:"[\n\t\t\t\t\t\t\t\t\t'title',\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\t\tmessage: 'Ingresa tu número de ticket',\n\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t]"}],attrs:{"autocomplete":"off"}})],1)],1),_c('div',{staticClass:"col-md-12"},[_c('a-form-item',{attrs:{"label":"Descripción"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
									'description',
									{
										rules: [
											{
												required: true,
												message: 'Ingresa tu número de ticket',
											},
										],
									},
								]),expression:"[\n\t\t\t\t\t\t\t\t\t'description',\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\t\tmessage: 'Ingresa tu número de ticket',\n\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t]"}]})],1)],1)]),_c('div',{staticClass:"row pt20"},[_c('div',{staticClass:"col-md-12 text-right"},[_c('a-button',{key:"back",on:{"click":_vm.onCloseModal}},[_vm._v(" Cancelar ")]),_c('a-button',{key:"submit",staticClass:"btn btn-success ml7",attrs:{"htmlType":"submit"}},[_vm._v(" Guardar ")])],1)])])],1),(!_vm.isNewRecord)?_c('a-tab-pane',{key:"comments",attrs:{"tab":"Comentarios","force-render":""}},[_c('a-form',{attrs:{"form":_vm.formComments},on:{"submit":_vm.handleSubmitComments}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('a-form-item',{attrs:{"label":"Comentario"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
									'content',
									{
										rules: [
											{
												required: true,
												message: 'Ingresa tu número de ticket',
											},
										],
									},
								]),expression:"[\n\t\t\t\t\t\t\t\t\t'content',\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\t\tmessage: 'Ingresa tu número de ticket',\n\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t]"}]})],1)],1)]),_c('div',{staticClass:"row pt20"},[_c('div',{staticClass:"col-md-12 text-right"},[_c('a-button',{key:"back",on:{"click":_vm.onCloseModal}},[_vm._v(" Cancelar ")]),_c('a-button',{key:"submit",staticClass:"btn btn-success ml7",attrs:{"htmlType":"submit"}},[_vm._v(" Guardar ")])],1)])]),_c('hr',{staticClass:"hrDotted"}),_vm._l((_vm.actualRecord.comments),function(comment,index){return _c('div',{key:index},[_c('div',[_c('b',[_vm._v(_vm._s(comment.fullName))]),_vm._v(" | "),_c('small',[_vm._v(_vm._s(comment.created_at))])]),_c('div',[_vm._v(" "+_vm._s(comment.content)+" ")]),_c('hr')])})],2):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }